import React from 'react';
import { graphql } from 'gatsby';

import Layout from 'components/Layout';
import Helmet from 'components/Helmet';
import { SlideDown } from '../components/Transition';
import styles from './blog.module.scss';
import PostRow from '../components/PostRow';

const BlogPage = ({ data: { allPrismicPost } }) => {
  const { edges } = allPrismicPost;
  return (
    <Layout>
      <Helmet title='Blog' />
      <SlideDown>
        <div className={styles.blogHome}>
          <div className={styles.mainColumn}>
            <div className={styles.posts}>
              {edges.map(({ node }) => (
                <PostRow key={`post-${node.uid}`} uid={node.uid} postData={node.data} />
              ))}
            </div>
          </div>
        </div>
      </SlideDown>
    </Layout>
  );
};

export default BlogPage;

export const pageQuery = graphql`
  query AllPosts {
    allPrismicPost(
      sort: { fields: [data___release_date, first_publication_date, last_publication_date], order: DESC }
    ) {
      edges {
        node {
          uid
          data {
            release_date
            title {
              text
            }
            featured_image {
              alt
              url
            }
            lead_in {
              html
              text
            }
          }
          first_publication_date
          last_publication_date
        }
      }
    }
  }
`;
